import * as colors from 'trinidad-ui/colors/hex';
export const DEFAULT = {
  SECONDARY: colors.NAVY,
  PRIMARY: colors.RED_100
};
export const ODDSET = {
  SECONDARY: colors.ODDSET_600,
  PRIMARY: colors.ODDSET_HEADER
};
export const BINGO = {
  SECONDARY: colors.BINGO_200,
  PRIMARY: colors.BINGO
};
export const BOMBEN = ODDSET;
export const CHALLENGE = ODDSET;
export const CASINO = {
  SECONDARY: colors.CASINO_200,
  PRIMARY: colors.CASINO
};
export const EUROJACKPOT = {
  SECONDARY: '#A72F00',
  PRIMARY: '#FF5908',
  PROGRESS_BAR_SECONDARY: '#f1e6e5',
  PROGRESS_BAR_PRIMARY: '#6F0900'
};
export const EUROPATIPSET = {
  SECONDARY: colors.EUROPATIPSET_500,
  PRIMARY: colors.EUROPATIPSET
};
export const EXTRATIPSET = {
  SECONDARY: colors.EXTRATIPSET_700,
  PRIMARY: colors.EXTRATIPSET
};
export const KENO = {
  SECONDARY: colors.KENO_200,
  PRIMARY: colors.KENO,
  PROGRESS_BAR_SECONDARY: '#f3ebd4',
  PROGRESS_BAR_PRIMARY: '#FFBF00'
};
export const KENO_XPRESS = {
  SECONDARY: colors.KENOXPRESS_100,
  PRIMARY: colors.KENOXPRESS
};
export const LORDAGSGODIS = {
  SECONDARY: colors.RED_100,
  PRIMARY: colors.RED
};
export const LOTTO = {
  SECONDARY: colors.LOTTO_100,
  PRIMARY: colors.LOTTO_200,
  PROGRESS_BAR_SECONDARY: '#dbedda',
  PROGRESS_BAR_PRIMARY: '#003800'
};
export const MALTIPSET = {
  SECONDARY: colors.MALTIPSET,
  PRIMARY: colors.MALTIPSET_400
};
export const MATCHEN = ODDSET;
export const MOMANG = {
  SECONDARY: colors.PNP_200,
  PRIMARY: colors.MOMANG
};
export const POWERPLAY = ODDSET;
export const RUBBET = {
  SECONDARY: colors.RED_100,
  PRIMARY: colors.RED
};
export const SKRAPSPEL = {
  SECONDARY: colors.GREY_800,
  PRIMARY: colors.SKRAPSPEL_SECONDARY
};
export const SKRAP_BINGO = SKRAPSPEL;
export const SKRAP_KRYSS = SKRAPSPEL;
export const SKRAP7AN = {
  SECONDARY: '#00927A',
  PRIMARY: colors.SKRAP7AN
};
export const STRYKTIPSET = {
  SECONDARY: colors.STRYKTIPSET_500,
  PRIMARY: colors.STRYKTIPSET,
  PROGRESS_BAR_SECONDARY: '#e6eaee',
  PROGRESS_BAR_PRIMARY: colors.STRYKTIPSET_500
};
export const TOPPTIPSET = {
  SECONDARY: colors.TOPPTIPSET_300,
  PRIMARY: colors.TOPPTIPSET
};
export const HASTAR = {
  SECONDARY: colors.RACING,
  PRIMARY: colors.RACING_100
};
export const TRISS = {
  SECONDARY: colors.TRISS_200,
  PRIMARY: colors.TRISS,
  PROGRESS_BAR_SECONDARY: '#f4edd0',
  PROGRESS_BAR_PRIMARY: colors.TRISS_200
};
export const VIKINGLOTTO = {
  SECONDARY: colors.VIKINGLOTTO_100,
  PRIMARY: colors.VIKINGLOTTO
};
export const PIX_MIXEN = {
  SECONDARY: colors.DATA_01,
  PRIMARY: colors.DATA_SECONDARY_SPORT
};
export const VINNARE = {
  SECONDARY: colors.DATA_01,
  PRIMARY: '#FEE3A8',
  PROGRESS_BAR_SECONDARY: colors.GREY_300,
  PROGRESS_BAR_PRIMARY: colors.NAVY
};
export const LYCKOPLATSEN = {
  SECONDARY: '#1047B8',
  PRIMARY: colors.LYCKOPLATSEN
};
export const SOC = {
  SECONDARY: colors.DATA_SECONDARY_SPORT,
  PRIMARY: '#D9FDFF',
  PROGRESS_BAR_SECONDARY: '#D9FDFF',
  PROGRESS_BAR_PRIMARY: colors.DATA_SECONDARY_SPORT
};
export const TUR = {
  SECONDARY: colors.TUR,
  PRIMARY: '#EAFBE5',
  PROGRESS_BAR_SECONDARY: '#EAFBE5',
  PROGRESS_BAR_PRIMARY: colors.TUR
};